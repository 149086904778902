import React, { useState, useLayoutEffect, useRef } from "react"
import Img from "gatsby-image"
import { navigate, graphql } from "gatsby"
import { useBreakpoint } from "hooks/use-breakpoint"
import { useCookies } from "react-cookie"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"

import Layout from "components/layout"
import SEO from "components/seo"
import { Helmet } from "react-helmet"
import PaddedContent from "components/paddedContent"
import PageHeader from "components/pageHeader"
import RichText from "components/richText"
import Video from "components/video"
import FormBlock from "components/formBlock"
import FormBlockSmall from "components/formBlockSmall"
import CtaBlock from "components/ctaBlock"
import SocialShare from "components/socialShare"
import WavyBackground from "components/wavyBackground"
import ArticlesGrid from "components/articlesGrid"
import useSiteMetadata from "hooks/use-site-metadata"
import CtaSection from "sections/ctaSection"

import styles from "styles/templates/article.module.scss"
import styleVars from "styles/variables.scss"
import CloseIcon from "images/icons/close.svg"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const cookieAge = 60 * 60 * 24 * 30 // 1 month in seconds
const articleCookieName = "submittedArticleForm"

const Article = ({ data }) => {
  const {
    title,
    slug,
    description,
    formattedDate,
    category,
    heroImage,
    heroVideo,
    text,
    requiredForm,
    featuredSection,
    relatedArticles,
    authorAvatar,
    authorName
  } = data.contentfulArticle
  const images = data.allContentfulAsset.nodes
  const richText = (text || {}).json || ''
  const subscribeForm = data.contentfulBlog.subscribeForm
  const ctaSection = data.contentfulBlog.sections

  const [formModal, setFormModal] = useState(requiredForm ? true : false)
  const [cookies, setCookie] = useCookies([articleCookieName])
  const [isLaptop, loaded] = useBreakpoint(styleVars.breakpointLaptop)
  const siteMetadata = useSiteMetadata()

  const articleUrl = `${siteMetadata.siteUrl}/blog/${slug}`

  const maxWidth = isLaptop ? "100%" : "700px"
  const isFeaturedForm = featuredSection?.__typename === "ContentfulForm"
  const isFeaturedCTA = featuredSection?.__typename === "ContentfulCtaSection"
  const hasSubmittedForm = cookies[articleCookieName] === "true"

  useLayoutEffect(() => {
    let timeout
    const scrollPage = () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      // window.scrollTo(0, 0)
      document.body.style.opacity = 0
      timeout = setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        document.body.style.opacity = 1
      }, 300);
    }

    window.addEventListener('popstate', scrollPage)
    return () => {
      window.removeEventListener('popstate', scrollPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ArticleSocialShare = ({ position, centered, style }) => {
    const bottom = position === "bottom"
    return (
      <SocialShare
        className={cx("shareContainer", { bottom })}
        centered={centered}
        style={style}
        title="Share this article"
        url={articleUrl}
      />
    )
  }

  const Aside = ({ position }) => {
    const bottom = position === "bottom"
    return (
      <aside className={cx("aside", { bottom })}>
        {authorAvatar && (<div className={styles.authorPanel}>
					<div className={styles.authorAvatar} style={{ backgroundImage: loaded && 
              `url(${authorAvatar?.file?.url})`
          }}></div>
          <div className={styles.authorName}>{authorName}</div>
        </div>)}
        {/* <ArticleSocialShare
          position={position}
          centered={bottom}
          style={{ marginBottom: "35px" }}
        /> */}
        <div className={styles.subscribeContainer}>
          <FormBlockSmall {...subscribeForm} centered={bottom} />
        </div>
      </aside>
    )
  }

  const wrapRichTextRef = useRef()
  return (
    <Layout color="navy" headerPadding site={data.contentfulSite}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify([{
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
              "@type": "WebPage",
            },
            "headline": title,
            "datePublished": formattedDate,
            "publisher": {
              "@type": "Organization",
              "name": "Encompass Technologies",
              "logo": {
                "@type": "ImageObject",
                "url": "https://images.ctfassets.net/j1fohgydbyek/2Z6gcmMEg4aC9CrAfJ3PJH/9bd55454f72632bdab75f7c4a8a2f2e6/vintrace-header-logo.svg"
              }
            },
            "description": richText.content && richText.content[0]?.content && richText.content[0].content[0]?.value,
            "articleBody": wrapRichTextRef.current?.innerText,
          },
          {
            "@type": "WebSite",
            "@id": "https://encompasstech.com",
            "url": "https://encompasstech.com",
            "name": "Encompass Technologies",
            "publisher": {
              "@id": "https://encompasstech.com/about/encompass"
            }
          }])}
        </script>
      </Helmet>
      <SEO
        title={title}
        description={description}
        image={`https:${heroImage?.file?.url}?w=1200&h=600`}
        slug={slug}
        article
      />

      <article>
        <PaddedContent>
          <div className={styles.headerContainer}>
            <PageHeader
              label={category?.text}
              title={title}
              subtext={description}
              maxWidthTitle={maxWidth}
              maxWidthSubtext={maxWidth}
              margin={"auto 0"}
            />
          </div>
          <div className={styles.flexContainer}>
            <div style={{ maxWidth: maxWidth }}>
              <div className={styles.heroContainer}>
                {!heroVideo && (
                  <Img className={styles.heroImage} fluid={heroImage?.fluid} alt={heroImage?.title || 'Hero Image'}/>
                )}
                {heroVideo && (
                  <Video {...heroVideo} style={{ maxWidth: "700px" }} />
                )}
              </div>
              <p className={styles.date}>{formattedDate}</p>
              <div ref={wrapRichTextRef}>
                <RichText text={richText} images={images} />
              </div>
            </div>
            {!isLaptop && <Aside />}
          </div>
        </PaddedContent>
        <PaddedContent mobilePadding={12}>
          {featuredSection && (
            <div className={styles.featuredSectionContainer}>
              {isFeaturedForm && (
                <FormBlock label="Featured Resource" {...featuredSection} />
              )}
              {isFeaturedCTA && (
                <CtaBlock {...featuredSection} style={{ maxWidth }} />
              )}
            </div>
          )}
          <ArticleSocialShare position="bottom" />
          {isLaptop && <Aside position="bottom" />}
        </PaddedContent>
      </article>
      <link rel="stylesheet" type="text/css"  href="/style/print.css" media="print" />

      {relatedArticles && (
        <WavyBackground above title="Related Posts">
          <ArticlesGrid articles={relatedArticles} />
        </WavyBackground>
      )}
      {/* {!relatedArticles && <WavyBackground />} */}

      {requiredForm && !hasSubmittedForm && (
        <Dialog
          isOpen={formModal}
          style={{
            padding: 0,
            width: "92vw",
            maxWidth: "1200px",
            position: "relative",
            zIndex: styleVars.popupZ,
          }}
          aria-label="Required form to access article"
        >
          <FormBlock
            {...requiredForm}
            style={{ boxShadow: "none" }}
            onSubmit={() => {
              setCookie(articleCookieName, "true", {
                path: "/",
                maxAge: cookieAge,
              })
              setFormModal(false)
            }}
          />
          <button className={styles.close} onClick={() => navigate("/blog/")}>
            <CloseIcon />
          </button>
        </Dialog>
      )}
      <CtaSection {...ctaSection}/>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ArticleBySlug($slug: String, $images: [String!]!, $locale: String="en-US") {
    contentfulArticle(slug: { eq: $slug }, node_locale: { eq:$locale }) {
      title
      slug
      description
      formattedDate: date(formatString: "MMMM D, YYYY")
      category {
        text
      }
      heroImage {
        title
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        file {
          url
        }
      }
      heroVideo {
        videoId
        youtubeVideoId
      }
      text {
        json
      }
      requiredForm {
        ...Form
      }
      featuredSection {
        ... on ContentfulCtaSection {
          ...CtaSection
        }
        ... on ContentfulForm {
          ...Form
        }
      }
      relatedArticles {
        ...ArticleThumbnail
      },
      authorAvatar {
        title
        file {
          url
        }
      },
      authorName
    }
    allContentfulAsset(filter: { file: { url: { in: $images } } }) {
      nodes {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
        file {
          url
        }
      }
    }
    contentfulBlog(slug: { eq: "blog" }) {
      subscribeForm {
        ...Form
      }
      sections {
        ... on Node {
          ... on ContentfulCtaSection {
            ...CtaSection
          }
        }
      }
    }
    contentfulSite(entryTitle: { eq: "Site" }, node_locale: {eq: $locale}) {
      headerLinks {
        ... on ContentfulMenuItem {
          id
          title
          internalLink
          externalLink
        }
        links {
          id
          linkText
          internalLink
          externalLink
          #icon
          openInNewTab
        }
      }
      footerLinks {
        id
        linkText
        internalLink
        externalLink
        sublinks {
          id
          linkText
          internalLink
          externalLink
        }
      }
      getADemoLinks {
        id
        internalLink
        linkText
      }
      footerText {
        footerText
      }
      footerContent {
        footerContent
      }
      footerLink {
        externalLink
        linkText
      }
    }
  }
`
